<template>
  <section :class="{parallax : active === 0}" class="home-section">
    <swiper style="border-radius: 15px;" :options="swiperOption" @transition-start="change" ref='swiperParallax'>
      <swiper-slide v-for="img in bannerSecondary" :key="img._id">
        <div class="background-image" :style="{'background-image': 'url(' + encodeURI(img.imageURL) + ')'}">
          <slider-content class="p-4 p-md-5"/>
        </div>
      </swiper-slide>
      <div class="swiper-button-next d-none d-lg-block" slot="button-next" style="right: calc(6% - 4px)">
        <icon-arrow-circle style="transform: rotate(180deg);" class="icon-hover" :size="50" :color="'#ffffff99'"/>
      </div>
      <div class="swiper-button-prev d-none d-lg-block" slot="button-prev" style="margin-left: 0px !important; left: calc(4% - 3px);" >
        <icon-arrow-circle  class="icon-hover" :size="50" :color="'#ffffff99'"/>
      </div>
    </swiper>
    <hr class="d-none d-md-block home-section">
  </section>
</template>

<script>
import IconArrowCircle from '../../../../common/svg/iconArrowCircle.vue'
import SliderContent from './SliderContent.vue'
import { mapGetters } from "vuex";

export default {
  components: { SliderContent, IconArrowCircle },
  name: "ParallaxHome",
  data() {
    return {
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      finish: true,
      active: 0,
      images: []
    }
  },
  computed:{
    ...mapGetters({
      loading: 'common/loading',
      bannerSecondary: 'home/bannerSecondary'
    })
  },
  methods:{
    change(){
      this.active = this.$refs.swiperParallax.$swiper.activeIndex 
    },
  },
  async mounted(){
    // let images = JSON.parse(localStorage.getItem("imagesBannerSecondary"))
    // this.images = images
  }
}
</script>

<style scoped>
.background-image{
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 535px;
}
.parallax > .swiper-container::v-deep > .swiper-wrapper{
  transform: none !important;
}
.swiper-container::v-deep > .swiper-button-prev.swiper-button-disabled, .swiper-container::v-deep > .swiper-button-next.swiper-button-disabled{
  pointer-events: visible;
}
.swiper-container::v-deep > .swiper-button-prev:hover > svg, .swiper-container::v-deep > .swiper-button-next:hover > svg{
  fill: #ffffffe6;
}
@media (max-width: 475px) {
  .background-image{
    height: auto;
    background-attachment: inherit;
    background-size: 163%;
    background-position-y: -40px;
  }
}
</style>
