<template>
  <div class="slider-container d-flex flex-column align-items-center align-items-lg-start justify-content-md-start h-100">
    <div class="content">
      <div class="text-center text-lg-start">
        <h1 class="mb-0 mb-md-2">{{ $t('home.slider.high_quality_materials') }}</h1>
        <h3 class="m-0 mt-2 mt-md-0 font-weight-bold w-100"> {{ $t('home.slider.good_sound')}}</h3>
      </div>
    </div>
    <div class="border-button">
      <button class="button-parallax size-20 weight-700 d-none d-md-block" style="padding: 8px 26px;" type="button">
          {{$t('home.slider.buy_now')}}
      </button>
    </div>
        <!-- <router-link to="/shop" href="#" class="btn">
        </router-link> -->
      <!-- <div class="bg-primary" style="width: 300px; height: 200px;"></div> -->
  </div>
</template>

<script>
export default {
  name:'SliderContent'
}
</script>
<style scoped>
  @media (max-width: 992px) {
    .border-button {
      margin-top: 5px;
    }
  }
  @media (min-width: 992px) {
    .border-button {
      margin-top: 10px;
    }
  }
.slider-container{
  min-height: 260px;
}
.slider-container > .content{
  max-width: 400px;
}

h1 {
  font-size: 36px;
  font-weight: 700;

  line-height: clamp(20px, 1em, 60px);
  text-transform: uppercase;

  color: #ffffff;
  /* text-shadow: 3px 1px 0px #F5AEC5 !important; */
  /* margin-bottom: 10px; */

}

h3 {
  color: white;
  font-size: 21px;
  font-weight: 300;
}

.button-parallax {
  position: relative;

  width: max-content;
  border: 0;
  border-radius: 5px;

  
  transition: all .15s ease-out;
  line-height: 26px;

  background: white;

  color: black;
  mix-blend-mode: screen;

  outline: none;
  z-index: 2;

  transition: var(--transition-1);
}

.border-button {
  margin-left: -8px;
  padding: 8px 6px;

  border: 2px solid transparent;
  border-radius: 7px;

  mix-blend-mode: screen;
  transition: var(--transition-1);
}
.border-button:hover{
  border: 2px solid white;
  transform: scale(0.92);
}
.border-button:hover .button-parallax {
  transform: scale(0.97);
}
@media (max-width: 475px) {
  h1{
    font-size: 24px;
  }
  h3{
    font-size: 18px;
  }
}
</style>
